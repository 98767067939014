// @see: https://gatsbyguides.com/tutorial/create-client-only-routes
// @see: https://www.gatsbyjs.org/docs/client-only-routes-and-user-authentication/
// @see: https://github.com/wiziple/gatsby-plugin-intl/issues/68
// @see: https://github.com/wiziple/gatsby-plugin-intl/issues/93

import React from 'react';
import { Router } from '@reach/router';
import Layout from '../components/layout';
import PrivateRoute from '../components/s4n/AccountManagement/PrivateRoute/PrivateRoute';
import Profile from '../components/s4n/AccountManagement/Profile/Profile';

const User = () => {
  return (
    <Layout>
      <Router>

        {/* @see: https://github.com/mglaman/commerce_demo_decoupled/blob/master/src/App.js */}
        {/* <Route path={`/cart`} component={Cart} />
        <Route path={`/checkout/:orderId`} component={Checkout} /> */}


        <PrivateRoute path="/:locale/user/profile" component={Profile} />
      </Router>
    </Layout>
  )
}

export default User;